import * as ActionTypes from '../constants/app.constants'
const initialState = {
  checkOnline: [],
  coords: null,
  loader: false,
  isButtonUser: false,
  isCategory: false,
  isCategoryMobile: false,
  subCategory: null,
  category: [],
  advertising: [],
  advertisements: [],
  swiperAdvertising: [],
  productUploadImagesProgress: 0,
  productCreateStage: 'start',
  isProgress: false,
  categoryId: null,
  isModalMobilePhone: null,
  errors: {
    category: null,
    advertising: null,
    advertisements: null,
  },
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_LOADER: {
      return { ...state, loader: action.payload }
    }
    case ActionTypes.TOGGLE_CATEGORY: {
      return { ...state, isCategory: action.payload }
    }
    case ActionTypes.TOGGLE_CATEGORY_MOBILE: {
      return { ...state, isCategoryMobile: action.payload }
    }
    case ActionTypes.TOGGLE_SUB_CATEGORY_MOBILE: {
      return { ...state, subCategory: action.payload }
    }
    case ActionTypes.ONLINE_USER_INFO_REQUEST: {
      return { ...state }
    }
    case ActionTypes.ONLINE_USER_INFO_SUCCESS: {
      return {
        ...state,
        checkOnline: action.payload,
      }
    }
    case ActionTypes.TOGGLE_COORDS: {
      return {
        ...state,
        coords: action.payload,
      }
    }
    case ActionTypes.ALL_CATEGORY_INFO_REQUEST: {
      return {
        ...state,
        errors: { ...state.errors, category: null },
      }
    }
    case ActionTypes.ALL_CATEGORY_INFO_SUCCESS: {
      const category = [...action.payload]
      return {
        ...state,
        errors: { ...state.errors, category: null },
        category,
      }
    }
    case ActionTypes.ALL_CATEGORY_INFO_ERROR: {
      return {
        ...state,
        errors: { ...state.errors, category: action.payload },
      }
    }
    case ActionTypes.ALL_ADVERTISEMENTS_INFO_REQUEST: {
      return {
        ...state,
        errors: { ...state.errors, advertisements: null },
      }
    }
    case ActionTypes.ALL_ADVERTISEMENTS_INFO_SUCCESS: {
      const advertisements = [...action.payload]
      return {
        ...state,
        errors: { ...state.errors, advertisements: null },
        advertisements,
      }
    }
    case ActionTypes.ALL_ADVERTISEMENTS_INFO_ERROR: {
      return {
        ...state,
        errors: { ...state.errors, advertisements: action.payload },
      }
    }

    case ActionTypes.ALL_SWIPER_INFO_REQUEST: {
      return {
        ...state,
      }
    }
    case ActionTypes.ALL_SWIPER_INFO_SUCCESS: {
      const swiperAdvertising = [...action.payload]
      return {
        ...state,
        swiperAdvertising,
      }
    }
    case ActionTypes.ALL_SWIPER_INFO_ERROR: {
      return {
        ...state,
      }
    }
    case ActionTypes.PRODUCT_CREATE_STAGE: {
      return {
        ...state,
        productCreateStage: action.payload,
      }
    }
    case ActionTypes.PRODUCT_CREATE_REQUEST: {
      return {
        ...state,
        isProgress: true,
      }
    }
    case ActionTypes.PRODUCT_UPLOAD_IMAGES_PROGRESS: {
      return {
        ...state,
        productUploadImagesProgress: action.payload,
      }
    }
    case ActionTypes.PRODUCT_CREATE_SUCCESS: {
      const advertisements = [...state.advertisements, action.payload]
      return {
        ...state,
        advertisements: advertisements,
        productUploadImagesProgress: 0,
        isProgress: false,
      }
    }
    case ActionTypes.PRODUCT_CREATE_ERROR: {
      return {
        ...state,
        productUploadImagesProgress: 0,
        isProgress: false,
      }
    }
    case ActionTypes.CATEGORY_ID: {
      return {
        ...state,
        categoryId: action.payload,
      }
    }
    case ActionTypes.IS_MODAL_MOBILE_PHONE: {
      return {
        ...state,
        isModalMobilePhone: action.payload,
      }
    }
    default:
      return state
  }
}
export default appReducer
