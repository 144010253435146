import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import dayjs from 'dayjs'
import { getAvatarUrl, getProductImage } from '../../api'
import MapYandex from '../MapYandex'
import Characteristics from '../Characteristics'
import * as actions from '../../actions'

function IdContainer({ id }) {
  const dispatch = useDispatch()
  const advertisements = useSelector((state) => state.app.advertisements)
  const userInfo = useSelector((state) => state.user.user)
  const [width, setWidth] = useState(window.innerWidth <= 992)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [isMap, setIsMap] = useState(false)
  const [isAllCharacteristics, setIsAllCharacteristics] = useState(false)
  const ref = useRef()
  const isFavorite =
    userInfo && userInfo.productId
      ? userInfo.productId.some((f) => f === advertisements.id)
      : false

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 992)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const product =
    id && advertisements && advertisements.length > 0
      ? advertisements.find((p) => p.id.toString() === id)
      : null

  return (
    <>
      {product && (
        <main className={styles.productId}>
          {!width && (
            <section className={styles.sectionTop}>
              <div className={styles.sectionTopContainer}>
                <div className={styles.sectionTopInner}>
                  <ul className={styles.breadcrumbs}>
                    <li className={styles.sectionTopLinkInner}>
                      <Link to={'/'} className={styles.linkT}>
                        Главная
                      </Link>
                    </li>
                    <div className={styles.star}>•</div>
                    <li className={styles.sectionTopLinkInner}>
                      <Link
                        to={
                          product.category.categoryRoute
                            ? `/${product.category.categoryRoute}`
                            : '/'
                        }
                        className={styles.linkT}
                      >
                        {product.category.name}
                      </Link>
                    </li>
                    <div className={styles.star}>•</div>
                    <li className={styles.sectionTopLinkInner}>
                      <Link
                        to={`/${product.category.columns.columnRoute}`}
                        className={styles.linkT}
                      >
                        {product.category.columns.name}
                      </Link>
                    </li>
                    {product.category.columns.subCategory && (
                      <>
                        <div className={styles.star}>•</div>
                        <li className={styles.sectionTopLinkInner}>
                          <Link
                            to={`/${product.category.columns.subCategory.subCategoryRoute}`}
                            className={styles.linkT}
                          >
                            {product.category.columns.subCategory.name}
                          </Link>
                        </li>
                      </>
                    )}
                    <div className={styles.star}>•</div>
                    <li className={styles.sectionTopLinkInner}>
                      <div className={styles.linkB}>{product.name}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          )}
          <div className={styles.productIdContainer}>
            <div className={styles.productIdWrapper}>
              <div className={styles.col1}>
                <div className={styles.colInner}>
                  {!width && (
                    <div className={styles.productNameDesctopContainer}>
                      <h2
                        data-test-block="ProductCaption"
                        className={styles.productCaption}
                      >
                        {product.name}
                      </h2>
                      <div className={styles.productIdAddToFavoryteContainer}>
                        <button className={styles.butonAddToFavorite}>
                          <div className={styles.favoriteToggleClick}>
                            <i className={styles.favoriteToggleClickInner}></i>
                            <i className={styles.favoriteToggleClickInner}></i>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill={
                                isFavorite ? 'rgb(247, 80, 89)' : 'currentColor'
                              }
                            >
                              <path d="M12.333 5.673L12 5.97l-.333-.298C10.487 4.618 9.229 4 8 4 4.962 4 3 5.962 3 9c0 4.01 3.244 7.656 8.842 10.975a.4.4 0 0 0 .326-.004C17.772 16.615 21 12.996 21 9c0-3.038-1.962-5-5-5-1.229 0-2.488.618-3.667 1.673zM16 3c3.59 0 6 2.41 6 6 0 4.452-3.44 8.308-9.311 11.824-.394.246-.98.246-1.366.006C5.46 17.353 2 13.466 2 9c0-3.59 2.41-6 6-6 1.39 0 2.746.61 4 1.641C13.254 3.61 14.61 3 16 3z"></path>
                            </svg>
                          </div>
                          <div
                            data-test-action="FavoriteToggleClick"
                            className={styles.addText}
                          >
                            <div>Добавить в избранное</div>
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                  <Swiper
                    style={{
                      '--swiper-navigation-color': '#fff',
                      '--swiper-pagination-color': '#fff',
                    }}
                    loop={true}
                    slidesPerView={1}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className={styles.swiper}
                  >
                    {product.album && product.album.length > 0 ? (
                      <>
                        {product.album.map((item, index) => (
                          <SwiperSlide
                            className={styles.swiperSlide}
                            key={`product_${item.image}_${index}`}
                          >
                            <img
                              src={getProductImage(item.image)}
                              alt={product.name}
                            />
                          </SwiperSlide>
                        ))}
                      </>
                    ) : (
                      <SwiperSlide className={styles.swiperSlide}>
                        <img
                          src="https://swiperjs.com/demos/images/nature-1.jpg"
                          alt={''}
                        />
                      </SwiperSlide>
                    )}
                  </Swiper>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={4.5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className={styles.mySwiper}
                  >
                    {product.album && product.album.length > 0 ? (
                      <>
                        {product.album.map((item, index) => (
                          <SwiperSlide
                            className={styles.mySwiperSlide}
                            key={`product_${index}_${index}`}
                          >
                            <img
                              src={getProductImage(item.image)}
                              alt={product.name}
                            />
                          </SwiperSlide>
                        ))}
                      </>
                    ) : (
                      <SwiperSlide className={styles.mySwiperSlide}>
                        <img
                          src="https://swiperjs.com/demos/images/nature-1.jpg"
                          alt={''}
                        />
                      </SwiperSlide>
                    )}
                  </Swiper>
                  {width ? (
                    <>
                      <div className={styles.productIdInfoM}>
                        <ul className={styles.productIdInfoMo}>
                          <li className={styles.productIdInfoMob}>
                            <div className={styles.productIdInfoMobi}>
                              <div className={styles.productIdInfoMPriceF}>
                                <h3 className={styles.colPriceinfoDTitleIM}>
                                  <span className={styles.colPriceinfoDTitleIn}>
                                    <span
                                      className={styles.colPriceinfoDTitleIne}
                                    >
                                      <span className={styles.priceCount}>
                                        {product.price.toString().length === 5
                                          ? product.price
                                              .toString()
                                              .slice(0, 2) +
                                            ' ' +
                                            product.price.toString().slice(2)
                                          : product.price.toString().length ===
                                              6
                                            ? product.price
                                                .toString()
                                                .slice(0, 3) +
                                              ' ' +
                                              product.price.toString().slice(3)
                                            : product.price}{' '}
                                        ₽
                                      </span>
                                    </span>
                                  </span>
                                </h3>
                                <button className={styles.butonAddToFavorite}>
                                  <div className={styles.favoriteToggleClick}>
                                    <i
                                      className={
                                        styles.favoriteToggleClickInner
                                      }
                                    ></i>
                                    <i
                                      className={
                                        styles.favoriteToggleClickInner
                                      }
                                    ></i>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill={
                                        isFavorite
                                          ? 'rgb(247, 80, 89)'
                                          : 'currentColor'
                                      }
                                    >
                                      <path d="M12.333 5.673L12 5.97l-.333-.298C10.487 4.618 9.229 4 8 4 4.962 4 3 5.962 3 9c0 4.01 3.244 7.656 8.842 10.975a.4.4 0 0 0 .326-.004C17.772 16.615 21 12.996 21 9c0-3.038-1.962-5-5-5-1.229 0-2.488.618-3.667 1.673zM16 3c3.59 0 6 2.41 6 6 0 4.452-3.44 8.308-9.311 11.824-.394.246-.98.246-1.366.006C5.46 17.353 2 13.466 2 9c0-3.59 2.41-6 6-6 1.39 0 2.746.61 4 1.641C13.254 3.61 14.61 3 16 3z"></path>
                                    </svg>
                                  </div>
                                </button>
                              </div>
                              <h1 className={styles.productCaptionM}>
                                {product.name}
                              </h1>
                              <div className={styles.butonContainerM}>
                                <button
                                  className={styles.butonVisibleNumberM}
                                  onClick={() => {
                                    dispatch(
                                      actions.isModalMobilePhone(product.user),
                                    )
                                  }}
                                >
                                  <span className={styles.visibleNumber}>
                                    Показать номер
                                  </span>
                                </button>
                                <button className={styles.createMessageM}>
                                  <span className={styles.visibleNumber}>
                                    Написать продавцу
                                  </span>
                                </button>
                              </div>
                            </div>
                          </li>
                          <li className={styles.productIdInfoMob}>
                            <button className={styles.butonOpenMapModal}>
                              <div className={styles.butonOpenMapModalI}>
                                <div className={styles.butonOpenMapModalIConI}>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    role="presentation"
                                  >
                                    <path
                                      fill="#7092fe"
                                      d="M12 22c-.95 0-2.916-1.623-4.565-3.606C5.209 15.718 4 12.894 4 10.1 4 5.628 7.58 2 12 2s8 3.628 8 8.1c0 2.793-1.21 5.618-3.435 8.294C14.916 20.377 12.95 22 12 22zm0-8a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                                    ></path>
                                  </svg>
                                  <div className={styles.geoText}>Москва</div>
                                </div>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  role="presentation"
                                >
                                  <path
                                    fill="#333"
                                    d="M10.058 7.765l.684-.73L16.031 12l-5.289 4.965-.684-.73L14.569 12z"
                                  ></path>
                                </svg>
                              </div>
                            </button>
                          </li>
                          <li className={styles.productIdInfoMob}>
                            <button className={styles.butonDr}>
                              <div className={styles.butonDrI}>
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                  <path
                                    fill="#6C94C0"
                                    fillRule="evenodd"
                                    d="M3.393 11.5l.643-3h6.214a.75.75 0 1 0 0-1.5H4.357l.389-1.814A1.5 1.5 0 0 1 6.213 4h8.932a1.5 1.5 0 0 1 1.466 1.814L16.143 8h.15a3 3 0 0 1 2.581 1.472l2.336 3.947a2 2 0 0 1 .258 1.302l-.223 1.562A2 2 0 0 1 19.265 18h-.436a3.001 3.001 0 0 1-5.658 0H13v-.015c-.07.01-.14.015-.213.015H8.83a3.001 3.001 0 0 1-5.72-.2 1.5 1.5 0 0 1-.72-1.614L3.07 13h6.18a.75.75 0 1 0 0-1.5H3.393zM6 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm11.686-7.448a2 2 0 0 0-1.704-.952H15.8l-.9 3.9h4.6l-1.814-2.948zM16 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                  ></path>
                                </svg>
                                <div className={styles.butonDrII}>
                                  <div className={styles.butonDrIT1}>
                                    Возможна доставка
                                  </div>
                                  <div className={styles.butonDrIT2}>
                                    При оплате онлайн доставка от 0 ₽ и 1
                                    рабочего дня
                                  </div>
                                </div>
                              </div>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                role="presentation"
                              >
                                <path
                                  fill="#333"
                                  d="M10.058 7.765l.684-.73L16.031 12l-5.289 4.965-.684-.73L14.569 12z"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className={styles.productIdInfoM}>
                        <ul className={styles.productIdInfoMo}>
                          <li className={styles.productIdInfoMob}>
                            <div className={styles.descryptCont}>
                              <dl className={styles.descryptContI}>
                                <dd className={styles.descryptContIW}>
                                  <p className={styles.descryptContIB}>
                                    {product.description}
                                  </p>
                                </dd>
                              </dl>
                            </div>
                          </li>
                          <li className={styles.productIdInfoMob}>
                            <div className={styles.descryptCont}>
                              <dl className={styles.descryptContIC}>
                                <dt className={styles.labelInfo}>Категория</dt>
                                <dd className={styles.labelInfoI}>
                                  {product.category.columns.name}
                                </dd>
                                <dt className={styles.labelInfo}>
                                  Подкатегория
                                </dt>
                                <dd className={styles.labelInfoI}>
                                  {product.category.columns.subCategory.name}
                                </dd>
                                <dt className={styles.labelInfo}>Цвет</dt>
                                <dd className={styles.labelInfoI}>Черный</dd>
                                <dt className={styles.labelInfo}>Сезон</dt>
                                <dd className={styles.labelInfoI}>Зима</dd>
                              </dl>
                            </div>
                          </li>
                          <li className={styles.productIdInfoMob}>
                            <div className={styles.descryptCont}>
                              <dl className={styles.descryptContIC}>
                                <dt className={styles.labelInfo}>
                                  В избранном
                                </dt>
                                <dd className={styles.labelInfoI}>4</dd>
                                <dt className={styles.labelInfo}>Просмотры</dt>
                                <dd className={styles.labelInfoI}>302</dd>
                                <dt className={styles.labelInfo}>Размещено</dt>
                                <dd className={styles.labelInfoI}>
                                  {dayjs().to(dayjs(product.createdAt))}
                                </dd>
                              </dl>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className={styles.productIdInfoM}>
                        <ul className={styles.productIdInfoMo}>
                          <li className={styles.productIdInfoMob}>
                            <Link className={styles.linkUserM}>
                              <div className={styles.linkUIn}>
                                <figure className={styles.linkTFig}>
                                  <div className={styles.userAvatarCM}>
                                    <img
                                      src="https://cdn0.youla.io/files/images/80_80/57/15/5715011fd53f3dc96144bbc7-1.jpg"
                                      width="48"
                                      height="48"
                                      alt="Алена Тиш"
                                      className={styles.userAvatarM}
                                    />
                                  </div>
                                  <div className={styles.userInfoIn}>
                                    <p className={styles.userProfileLinkInner}>
                                      <Link
                                        to={'/'}
                                        className={styles.profileLink}
                                      >
                                        Алена Тиш (1 объявление)
                                      </Link>
                                    </p>
                                    <p className={styles.userProfileLinkInner}>
                                      на&nbsp;Atuta&nbsp;с&nbsp;
                                      {dayjs(product.user.createdAt).format(
                                        'D MMM YYYY',
                                      )}
                                    </p>
                                    <div className={styles.ratingContainer}>
                                      <div className={styles.ratingInfoInner}>
                                        <div className={styles.ratingInfoWrap}>
                                          <span className={styles.ratingCount}>
                                            5,0
                                          </span>
                                          <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="#FFCD4B"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M8 12.557l3.711 2.366a.5.5 0 0 0 .757-.53l-.991-4.508 3.338-3.058a.5.5 0 0 0-.292-.866l-4.375-.397-1.684-4.202a.5.5 0 0 0-.928 0L5.85 5.564l-4.374.397a.5.5 0 0 0-.292.866l3.338 3.058-.992 4.509a.5.5 0 0 0 .758.529L8 12.557z"
                                            ></path>
                                          </svg>
                                        </div>
                                        <button className={styles.userRate}>
                                          1 отзыв
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </figure>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  role="presentation"
                                >
                                  <path
                                    fill="#333"
                                    d="M10.058 7.765l.684-.73L16.031 12l-5.289 4.965-.684-.73L14.569 12z"
                                  ></path>
                                </svg>
                              </div>
                            </Link>
                          </li>
                          <li className={styles.productIdInfoMob}>
                            <div className={styles.descryptCont}>
                              <div className={styles.verifiProfileContainer}>
                                <div className={styles.verryfiInfoIcconInner}>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <g fillRule="nonzero" fill="none">
                                      <path
                                        fill="#77C226"
                                        d="M12 .6l-9 4v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12v-6l-9-4"
                                      ></path>
                                      <path
                                        fill="#FFF"
                                        d="M12 12.07c3.29 0 6 1.32 6 3-.24.53-.53 1.03-.87 1.53A9.69 9.69 0 0 1 12 20.52a9.69 9.69 0 0 1-5.13-3.92c-.34-.5-.63-1-.87-1.53 0-1.65 2.71-3 6-3zm0-7.47a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                                <div className={styles.vkVerifyM}>
                                  <span className="sc-cOxWqc sc-kfNCuK fhzvlf cqMHBk">
                                    Подтвержденный профиль
                                  </span>
                                  <div className={styles.verryfiInfoWrapper}>
                                    <div className={styles.verryfiInfoWrap}>
                                      <div className={styles.verryfiInfoIcconI}>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                        >
                                          <path
                                            fill="#858585"
                                            fillRule="evenodd"
                                            d="M9.804 1.96a7.843 7.843 0 1 1 0 15.687 7.843 7.843 0 0 1 0-15.686zm0 .981a6.863 6.863 0 1 0 0 13.726 6.863 6.863 0 0 0 0-13.726zm.735 5.932v4.902H9.07V8.873h1.47zm-.735-2.99a.98.98 0 1 1 0 1.96.98.98 0 0 1 0-1.96z"
                                            opacity="0.48"
                                          ></path>
                                        </svg>
                                      </div>
                                      <div className={styles.toltipBox}>
                                        <span
                                          className={styles.toltipContainer}
                                        >
                                          <div className={styles.toltipIn}>
                                            <p className={styles.toltipText}>
                                              Профиль продавца подтвержден через
                                              ВКонтакте и с помощью видео-селфи
                                            </p>
                                            <button
                                              type="button"
                                              color="grey"
                                              className={styles.toltipDetail}
                                            >
                                              Узнать подробнее
                                            </button>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.videoSelf}>
                                <div className={styles.videoSelfIconIn}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M5.6 7.8a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4.8 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 14.4A6.408 6.408 0 0 1 1.6 8c0-.232.016-.464.04-.688a8.05 8.05 0 0 0 4.168-4.296 7.98 7.98 0 0 0 8.328 3.176c.168.568.264 1.176.264 1.808 0 3.528-2.872 6.4-6.4 6.4z"
                                      fill="#9EAFB9"
                                      fillRule="nonzero"
                                    ></path>
                                  </svg>
                                </div>
                                <div className={styles.videoSelfWr}>
                                  <p className={styles.videoSelfText}>
                                    Видео-селфи проверено
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className={styles.productIdInfoMob}>
                            <div className={styles.descryptCont}>
                              <button className={styles.subsSel}>
                                <div className={styles.subsSelT}>
                                  Подписаться на продавца
                                </div>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  role="presentation"
                                >
                                  <path
                                    fill="#333"
                                    fillRule="evenodd"
                                    d="M17 6h3v1h-3v3h-1V7h-3V6h3V3h1v3zm3 10.908c-.033-.558-.25-1.068-.66-1.543-.428-.496-1.053-.935-1.878-1.31l.414-.91c.948.431 1.69.952 2.221 1.566.55.637.854 1.353.903 2.167v2.352l-.212.15c-2.424 1.706-5.358 2.557-8.788 2.557-3.42 0-6.354-.851-8.787-2.557L3 19.23v-.26l.001-2.127c.05-.72.329-1.382.828-1.976.475-.564 1.199-1.127 2.174-1.698l.506.862c-.885.519-1.524 1.015-1.915 1.48-.366.435-.56.898-.594 1.367v1.829c2.216 1.486 4.879 2.23 8 2.23 3.13 0 5.793-.744 8-2.23v-1.799zM14.688 2.755l-.554.833C13.554 3.202 12.856 3 12.05 3 9.316 3 7.768 5.441 8.158 8.945 8.56 12.577 10.095 15 12.05 15c1.363 0 2.522-1.146 3.255-3.187l.941.337c-.86 2.396-2.33 3.85-4.196 3.85-2.617 0-4.433-2.869-4.886-6.945C6.715 5.021 8.63 2 12.05 2c1 0 1.89.258 2.637.755z"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className={styles.productIdInfoM}>
                        <ul className={styles.productIdInfoMo}>
                          <li className={styles.productIdInfoMob}>
                            <button className={styles.subsSel}>
                              <div className={styles.selSM}>
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                  <g fill="#77C026" fillRule="evenodd">
                                    <path d="M21 4v9.506c0 3.4-2.238 6.406-5.524 7.422L12 22l-3.476-1.072C5.238 19.912 3 16.906 3 13.506V4l9-2 9 2zm-9.477 12.29v-.69h1.619c.23 0 .411-.057.545-.172a.589.589 0 0 0 .202-.472.6.6 0 0 0-.202-.477c-.134-.117-.316-.175-.545-.175H11.56V13.25h1.406c.752 0 1.385-.13 1.9-.388a2.642 2.642 0 0 0 1.161-1.088c.26-.466.389-1.01.389-1.63 0-.64-.135-1.195-.403-1.666a2.701 2.701 0 0 0-1.172-1.091c-.513-.256-1.13-.385-1.853-.385H11.09c-.513 0-.893.124-1.14.37-.246.247-.369.631-.369 1.154v3.179H9.04c-.269.004-.478.076-.63.216a.724.724 0 0 0-.227.56c0 .24.076.426.227.56.152.135.361.204.63.209h.542v1.055H8.93c-.234 0-.417.058-.55.175a.605.605 0 0 0-.197.477c0 .2.066.357.198.472.132.115.315.172.55.172h.688v.689c.048.249.16.441.333.578a.97.97 0 0 0 .619.205.97.97 0 0 0 .619-.205.936.936 0 0 0 .333-.578z"></path>
                                    <path d="M12.482 11.705c.664 0 1.15-.12 1.458-.36.308-.239.461-.637.461-1.193 0-.552-.146-.952-.44-1.201-.292-.25-.73-.374-1.31-.374h-1.099v3.128h.93z"></path>
                                  </g>
                                </svg>
                                <div className={styles.selSMC}>
                                  <div className={styles.selSMC1}>
                                    Безопасная сделка
                                  </div>
                                  <div className={styles.selSMC2}>
                                    {'При оплате онлайн сразу вернем деньги'}
                                  </div>
                                  <div className={styles.selSMC2}>
                                    если товар не устроит
                                  </div>
                                </div>
                              </div>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                role="presentation"
                              >
                                <path
                                  fill="#333"
                                  d="M10.058 7.765l.684-.73L16.031 12l-5.289 4.965-.684-.73L14.569 12z"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className={styles.productIdInfoM}>
                        <ul className={styles.listEndM}>
                          <li className={styles.liEnd}>
                            <button className={styles.subsSel}>
                              <div className={styles.subsSelT}>
                                Пожаловаться на объявление
                              </div>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                role="presentation"
                              >
                                <path
                                  fill="#333"
                                  d="M10.058 7.765l.684-.73L16.031 12l-5.289 4.965-.684-.73L14.569 12z"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <ul className={styles.desctopInfoC}>
                      <li className={styles.desctopInfoCW}>
                        <dt className={styles.desctopInfoCWLable}>
                          Местоположение
                        </dt>
                        <dd className={styles.subsLab}>
                          <div className={styles.openMapDC}>
                            <div className={styles.openMapDCT}>
                              {product.country}
                            </div>
                            <button
                              className={styles.vievMap}
                              onClick={() => {
                                setIsMap(!isMap)
                              }}
                            >
                              <span className={styles.vievMapT}>
                                Показать на карте
                              </span>
                            </button>
                          </div>
                        </dd>
                        {isMap && (
                          <div className={styles.map}>
                            <MapYandex
                              coords={JSON.parse(product.coords)}
                              width={'100%'}
                              height={400}
                              ymapsRef={ref}
                            />
                          </div>
                        )}
                      </li>
                      {product.description && (
                        <li className={styles.desctopInfoCW}>
                          <dt className={styles.desctopInfoCWLable}>
                            Описание
                          </dt>
                          <dd className={styles.subsLab}>
                            <p className={styles.subsLabD}>
                              {product.description}
                            </p>
                          </dd>
                        </li>
                      )}
                      <li className={styles.desctopInfoCW}>
                        <div className={styles.categ}>
                          <dt className={styles.desctopInfoCWLable}>
                            Категория
                          </dt>
                          <dd className={styles.subsLab}>
                            {product.category.columns.name}
                          </dd>
                        </div>
                        {product.category.columns.subCategory && (
                          <div className={styles.categ}>
                            <dt className={styles.desctopInfoCWLable}>
                              Подкатегория
                            </dt>
                            <dd className={styles.subsLab}>
                              {product.category.columns.subCategory.name}
                            </dd>
                          </div>
                        )}
                        {product.typeA && (
                          <>
                            <dt className={styles.desctopInfoCWLable}>Тип</dt>
                            <dd className={styles.subsLab}>{product.typeA}</dd>
                          </>
                        )}
                        <Characteristics label={'Бренд'} name={product.brand} />
                        {product.color && (
                          <div className={styles.categ}>
                            <dt className={styles.desctopInfoCWLable}>Цвет</dt>
                            <dd className={styles.subsLab}>{product.color}</dd>
                          </div>
                        )}
                        {product.season && (
                          <div className={styles.categ}>
                            <dt className={styles.desctopInfoCWLable}>Сезон</dt>
                            <dd className={styles.subsLab}>{product.season}</dd>
                          </div>
                        )}
                        {product.state && (
                          <div className={styles.categ}>
                            <dt className={styles.desctopInfoCWLable}>
                              Состояние товара
                            </dt>
                            <dd className={styles.subsLab}>{product.state}</dd>
                          </div>
                        )}
                        {isAllCharacteristics && (
                          <>
                            <Characteristics
                              label={'Размеры'}
                              name={product.size}
                            />
                            <Characteristics
                              label={'Сезон'}
                              name={product.season}
                            />
                            <Characteristics
                              label={'wi-fi'}
                              name={product.wifi}
                            />
                            <Characteristics
                              label={'wi-fi'}
                              name={product.length}
                            />
                            <Characteristics label={'Пол'} name={product.sex} />
                            <Characteristics
                              label={'Длинна'}
                              name={product.sizeHeight}
                            />
                            <Characteristics
                              label={'Группа'}
                              name={product.weightGroup}
                            />
                            <Characteristics
                              label={'Крепление'}
                              name={product.fasteningC}
                            />
                            <Characteristics
                              label={'Крепление'}
                              name={product.fasteningC}
                            />
                            {product.peculiaritiesC && (
                              <>
                                <></>
                              </>
                            )}
                            {product.adjustmentsC && (
                              <>
                                <></>
                              </>
                            )}
                            <Characteristics
                              label={'Количество блоков'}
                              name={product.strollersBl}
                            />
                            <Characteristics
                              label={'Тип колес'}
                              name={product.strollersCir}
                            />
                            <Characteristics
                              label={'Операционная система'}
                              name={product.operatingOs}
                            />
                            <Characteristics
                              label={'Встроенная память'}
                              name={product.builtMemory}
                            />
                            <Characteristics
                              label={'Диагональ'}
                              name={product.diagonal}
                            />
                            <Characteristics
                              label={'Оперативная память'}
                              name={product.ozu}
                            />
                            <Characteristics
                              label={'Основная камера'}
                              name={product.camera1}
                            />
                            <Characteristics
                              label={'Фронтальная камера'}
                              name={product.camera2}
                            />
                            <Characteristics
                              label={'Совместимость с Android'}
                              name={product.compatibilityA}
                            />
                            <Characteristics
                              label={'Совместимость с iOS'}
                              name={product.compatibilityI}
                            />
                            <Characteristics
                              label={'Влагозащита'}
                              name={product.moistureProtection}
                            />
                            <Characteristics
                              label={'Шагомер'}
                              name={product.pedometer}
                            />
                            <Characteristics
                              label={'Пульсометр'}
                              name={product.heartMete}
                            />
                            <Characteristics
                              label={'Мониторинг сна'}
                              name={product.sleepMete}
                            />
                            <Characteristics
                              label={'Телефонные звонки'}
                              name={product.phoneCall}
                            />
                            <Characteristics
                              label={'Автоответчик'}
                              name={product.autoResp}
                            />
                            <Characteristics label={'АОН'} name={product.aon} />
                            <Characteristics
                              label={'Устройство'}
                              name={product.device}
                            />
                            <Characteristics
                              label={'АОН'}
                              name={product.deviceM}
                            />
                            <Characteristics
                              label={'Разъем подключения'}
                              name={product.connectT}
                            />
                            <Characteristics
                              label={'Наличие кабеля'}
                              name={product.cable}
                            />
                            <Characteristics
                              label={'Выходная сила тока'}
                              name={product.amperes}
                            />
                            <Characteristics
                              label={'Количество портов USB'}
                              name={product.usbCount}
                            />
                            <Characteristics
                              label={'Материал'}
                              name={product.materialCh}
                            />
                            <Characteristics
                              label={'Категория камеры'}
                              name={product.categCam}
                            />
                            <Characteristics
                              label={'Видоискатель'}
                              name={product.viewfinder}
                            />
                            <Characteristics
                              label={'Стабилизатор изображения'}
                              name={product.imageStabilizer}
                            />
                            <Characteristics
                              label={'Качество видео'}
                              name={product.videoQuality}
                            />
                            <Characteristics
                              label={'Формат носителя'}
                              name={product.typeSd}
                            />
                            <Characteristics
                              label={'Оптический Zoom'}
                              name={product.zoomOp}
                            />
                            <Characteristics
                              label={'Экшн-камера'}
                              name={product.actionCamera}
                            />
                            <Characteristics
                              label={'Съемка в 360 градусов'}
                              name={product.r360}
                            />
                            <Characteristics
                              label={'Тип подключения'}
                              name={product.conType}
                            />
                            <Characteristics
                              label={'Датчик движения'}
                              name={product.motionSen}
                            />
                            <Characteristics
                              label={'Встроенный микрофон'}
                              name={product.microphone}
                            />
                            <Characteristics
                              label={'Тип байонета'}
                              name={product.mountType}
                            />
                            <Characteristics
                              label={'Автофокус'}
                              name={product.autofocus}
                            />
                            <Characteristics
                              label={'Поворотная головка'}
                              name={product.swivelHead}
                            />
                            <Characteristics
                              label={'Воспроизведение видео'}
                              name={product.playVid}
                            />
                            <Characteristics
                              label={'Общий объем всех накопителей'}
                              name={product.ssd}
                            />
                            <Characteristics
                              label={'Тип видеокарты'}
                              name={product.videoTy}
                            />
                            <Characteristics
                              label={'Игровой'}
                              name={product.game}
                            />
                            <Characteristics
                              label={'Тип оптического привода'}
                              name={product.opticalDrive}
                            />
                            <Characteristics
                              label={'Разъем HDMI'}
                              name={product.hdmi}
                            />
                            <Characteristics
                              label={'Встроенные динамики'}
                              name={product.speakers}
                            />
                            <Characteristics
                              label={'USB разъем'}
                              name={product.usb}
                            />
                            <Characteristics
                              label={'Smart TV'}
                              name={product.smartTV}
                            />
                            <Characteristics
                              label={'Поддержка 3D'}
                              name={product.t3d}
                            />
                            <Characteristics
                              label={'Разрешение экрана'}
                              name={product.hd}
                            />
                            <Characteristics
                              label={'Поддержка HDR'}
                              name={product.hdr}
                            />
                            <Characteristics
                              label={'Разрешение'}
                              name={product.sizeDisplay}
                            />
                            <Characteristics
                              label={'Караоке'}
                              name={product.karaoke}
                            />
                            <Characteristics
                              label={'Тип акустики'}
                              name={product.acousticType}
                            />
                            <Characteristics
                              label={'Портативный'}
                              name={product.portable}
                            />
                            <Characteristics
                              label={'Сенсорный экран'}
                              name={product.touchScreen}
                            />
                            <Characteristics
                              label={'Радио'}
                              name={product.fm}
                            />
                            <Characteristics
                              label={'Тип дисплея'}
                              name={product.displayType}
                            />
                            <Characteristics
                              label={'Цветной экран'}
                              name={product.displayColor}
                            />
                            <Characteristics
                              label={'Подсветка'}
                              name={product.backlight}
                            />
                            <Characteristics
                              label={'Беспроводные'}
                              name={product.wireless}
                            />
                            <Characteristics
                              label={'Регулировка громкости'}
                              name={product.volumeCh}
                            />
                            <Characteristics
                              label={'Водонепроницаемость'}
                              name={product.waterN}
                            />
                            <Characteristics
                              label={'Шумоподавление'}
                              name={product.noiseReduction}
                            />
                            <Characteristics
                              label={'Тип подключения'}
                              name={product.connectors}
                            />
                            <Characteristics
                              label={'Область применения'}
                              name={product.scopeOfApplication}
                            />
                            <Characteristics
                              label={'Безопасность'}
                              name={product.safety}
                            />
                            <Characteristics
                              label={'Таймер'}
                              name={product.timer}
                            />
                            <Characteristics
                              label={'Тип установки'}
                              name={product.installationType}
                            />
                            <Characteristics
                              label={'Вместимость посуды'}
                              name={product.utensilCapacity}
                            />
                            <Characteristics
                              label={'Защита от протечек'}
                              name={product.leakProtection}
                            />
                            <Characteristics
                              label={'Отсрочка запуска'}
                              name={product.delayStart}
                            />
                            <Characteristics
                              label={'Тип уборки'}
                              name={product.typeCleaning}
                            />
                            <Characteristics
                              label={'Загрузка, кг'}
                              name={product.kg}
                            />
                            <Characteristics
                              label={'Скорость отжима'}
                              name={product.spin}
                            />
                            <Characteristics
                              label={'Сушка'}
                              name={product.drying}
                            />
                            <Characteristics
                              label={'Тип загрузки'}
                              name={product.loadType}
                            />
                            <Characteristics
                              label={'Высота, см'}
                              name={product.height}
                            />
                            <Characteristics
                              label={'Глубина, см'}
                              name={product.depth}
                            />
                            <Characteristics
                              label={'Ширина, см'}
                              name={product.width}
                            />
                            <Characteristics
                              label={'Зона применения'}
                              name={product.applicationArea}
                            />
                            <Characteristics
                              label={'Вид спорта'}
                              name={product.typeSport}
                            />
                            <Characteristics
                              label={'Для детей'}
                              name={product.forChild}
                            />
                            <Characteristics
                              label={'Рама / Рост велосипедиста'}
                              name={product.frame}
                            />
                            <Characteristics
                              label={'Платформа'}
                              name={product.platform}
                            />
                            <Characteristics
                              label={'Жанр'}
                              name={product.genre}
                            />
                            <Characteristics
                              label={'Формат'}
                              name={product.format}
                            />
                            <Characteristics
                              label={'Тематика'}
                              name={product.theme}
                            />
                            {/*<Characteristics*/}
                            {/*  label={'USB разъем'}*/}
                            {/*  name={product.typeService}*/}
                            {/*/>*/}
                            <Characteristics
                              label={'Стоимость указана'}
                              name={product.priceOf}
                            />
                            <Characteristics
                              label={'Место оказания'}
                              name={product.placeDelivery}
                            />
                            <Characteristics
                              label={'Тип квартиры'}
                              name={product.apartmentType}
                            />
                            <Characteristics
                              label={'Год постройки'}
                              name={product.yearConstruction}
                            />
                            <Characteristics
                              label={'Тип дома'}
                              name={product.houseType}
                            />
                            <Characteristics
                              label={'До метро'}
                              name={product.toTheMetro}
                            />
                            <Characteristics
                              label={'Лифты'}
                              name={product.elevators}
                            />
                            <Characteristics label={'Газ'} name={product.gas} />
                            {product.infrastructure && (
                              <>
                                <></>
                              </>
                            )}
                            <Characteristics
                              label={'Комнат в квартире'}
                              name={product.roomsCount}
                            />
                            <Characteristics
                              label={'Этаж'}
                              name={product.floor}
                            />
                            <Characteristics
                              label={'Этажность дома'}
                              name={product.floorH}
                            />
                            <Characteristics
                              label={'Общая площадь'}
                              name={product.totalArea}
                            />
                            <Characteristics
                              label={'Жилая площадь'}
                              name={product.livingArea}
                            />
                            <Characteristics
                              label={'Площадь кухни'}
                              name={product.kitchenArea}
                            />
                            <Characteristics
                              label={'Высота потолка'}
                              name={product.ceilingHeight}
                            />
                            <Characteristics
                              label={'Ремонт'}
                              name={product.repair}
                            />
                            <Characteristics
                              label={'Санузлы'}
                              name={product.bathrooms}
                            />
                            <Characteristics
                              label={'Балкон'}
                              name={product.balcony}
                            />
                            <Characteristics
                              label={'Площадь комнаты'}
                              name={product.roomArea}
                            />
                            <Characteristics
                              label={'Материал дома'}
                              name={product.materHouse}
                            />
                            <Characteristics
                              label={'Количество спален'}
                              name={product.romSlipC}
                            />
                            <Characteristics
                              label={'Электричество'}
                              name={product.electricity}
                            />
                            <Characteristics
                              label={'Отопление'}
                              name={product.heating}
                            />
                            <Characteristics
                              label={'Водоснабжение'}
                              name={product.waterSupply}
                            />
                            <Characteristics
                              label={'Гараж'}
                              name={product.garage}
                            />
                            <Characteristics
                              label={'Площадь участка'}
                              name={product.plotArea}
                            />
                            <Characteristics
                              label={'Тип участка'}
                              name={product.plotType}
                            />
                            <Characteristics
                              label={'Холодильник'}
                              name={product.fridge}
                            />
                            <Characteristics
                              label={'Посудомоечная машина'}
                              name={product.dishwasher}
                            />
                            <Characteristics
                              label={'Стиральная машина'}
                              name={product.washingMachine}
                            />
                            <Characteristics
                              label={'Предоплата'}
                              name={product.prepayment}
                            />
                            <Characteristics
                              label={'Коммунальные услуги'}
                              name={product.publicUtilities}
                            />
                            <Characteristics
                              label={'Тип дома'}
                              name={product.typeBuilding}
                            />
                            <Characteristics
                              label={'Тип сделки'}
                              name={product.transactionType}
                            />
                            <Characteristics
                              label={'Торг возможен'}
                              name={product.negotiable}
                            />
                            <Characteristics
                              label={'Порода'}
                              name={product.breed}
                            />
                            <Characteristics
                              label={'Тип товара'}
                              name={product.productType}
                            />
                            <Characteristics
                              label={'Год выпуска'}
                              name={product.yearIssue}
                            />
                            <Characteristics
                              label={'Руль'}
                              name={product.rul}
                            />
                            <Characteristics
                              label={'Кузов'}
                              name={product.body}
                            />
                            <Characteristics
                              label={'Количество дверей'}
                              name={product.countDor}
                            />
                            <Characteristics
                              label={'Мощность'}
                              name={product.horseW}
                            />
                            <Characteristics
                              label={'Тип двигателя'}
                              name={product.engineType}
                            />
                            <Characteristics
                              label={'Объем двигателя'}
                              name={product.engineSize}
                            />
                            <Characteristics
                              label={'Коробка передач'}
                              name={product.transmission}
                            />
                            <Characteristics
                              label={'Привод'}
                              name={product.drive}
                            />
                            <Characteristics
                              label={'Пробег'}
                              name={product.mileage}
                            />
                            <Characteristics
                              label={'Состояние автомобиля'}
                              name={product.stateAuto}
                            />
                            <Characteristics
                              label={'Не растаможен'}
                              name={product.customsA}
                            />
                            <Characteristics
                              label={'Автомобиль на гарантии'}
                              name={product.guarantees}
                            />
                            <Characteristics
                              label={'Количество владельцев по ПТС'}
                              name={product.pts}
                            />
                            <Characteristics label={'VIN'} name={product.vin} />
                            <Characteristics
                              label={'Климат-контроль'}
                              name={product.climateControl}
                            />
                            <Characteristics
                              label={'Электрические стеклоподъемники'}
                              name={product.windowLifters}
                            />
                            <Characteristics
                              label={'Обогрев сидений'}
                              name={product.heatedSeats}
                            />
                            <Characteristics
                              label={'Регулировка руля'}
                              name={product.rulR}
                            />
                            <Characteristics
                              label={'Кондиционер'}
                              name={product.conditioner}
                            />
                            <Characteristics
                              label={'Обогрев рулевого колеса'}
                              name={product.rulT}
                            />
                            <Characteristics
                              label={'Тонированные стекла'}
                              name={product.tinted}
                            />
                            <Characteristics
                              label={'Регулировка сиденья водителя'}
                              name={product.seatRegV}
                            />
                            <Characteristics
                              label={'Регулировка сиденья пассажира'}
                              name={product.seatRegP}
                            />
                            <Characteristics
                              label={'Обивка салона'}
                              name={product.upholstery}
                            />
                            <Characteristics
                              label={'Количество мест'}
                              name={product.seatCount}
                            />
                            <Characteristics
                              label={'Люк'}
                              name={product.luke}
                            />
                            <Characteristics
                              label={'Кожаный руль'}
                              name={product.steeringWheel}
                            />
                            <Characteristics
                              label={'Панорамная крыша'}
                              name={product.panoramicRoof}
                            />
                            <Characteristics
                              label={'Центральный замок'}
                              name={product.centralLock}
                            />
                            <Characteristics
                              label={'Сигнализация'}
                              name={product.signaling}
                            />
                            <Characteristics
                              label={'Иммобилайзер'}
                              name={product.immobilizer}
                            />
                            <Characteristics
                              label={'Легкосплавные диски'}
                              name={product.alloyWheels}
                            />
                            <Characteristics
                              label={'Аэродинамический обвес'}
                              name={product.aeroBody}
                            />
                            <Characteristics
                              label={'Фаркоп'}
                              name={product.towbar}
                            />
                            <Characteristics
                              label={'Зимние шины'}
                              name={product.winterTires}
                            />
                            <Characteristics
                              label={'Усилитель руля'}
                              name={product.powerSteering}
                            />
                            <Characteristics
                              label={'Система адаптации к дорожным условиям'}
                              name={product.roadAdaptation}
                            />
                            <Characteristics
                              label={'Круиз-контроль'}
                              name={product.cruiseControl}
                            />
                            <Characteristics
                              label={'Тип автомобильных фар'}
                              name={product.headlightsT}
                            />
                            <Characteristics
                              label={'Противотуманные фары'}
                              name={product.fogLights}
                            />
                            <Characteristics
                              label={'Омыватели фар'}
                              name={product.headlightWashers}
                            />
                            <Characteristics
                              label={'Датчик света'}
                              name={product.lightSensor}
                            />
                            <Characteristics
                              label={'Электрические зеркала'}
                              name={product.electricMirrors}
                            />
                            <Characteristics
                              label={'Датчик дождя'}
                              name={product.rainSensor}
                            />
                            <Characteristics
                              label={'Обогрев зеркал'}
                              name={product.heatedMirrors}
                            />
                            <Characteristics
                              label={
                                'Подушка безопасности фронтальная водителя'
                              }
                              name={product.airbag}
                            />
                            <Characteristics
                              label={
                                'Помощь при экстренном торможении (EBA/Break assist)'
                              }
                              name={product.eba}
                            />
                            <Characteristics
                              label={'Антиблокировочная система тормозов (ABS)'}
                              name={product.abs}
                            />
                            <Characteristics
                              label={
                                'Система курсовой стабилизации (ESP / ESC / DSC / VSA)'
                              }
                              name={product.esp}
                            />
                            <Characteristics
                              label={
                                'Антипробуксовочная система (ASR / TCS / TRC)'
                              }
                              name={product.asr}
                            />
                            <Characteristics
                              label={'Бортовой компьютер'}
                              name={product.boardComputer}
                            />
                            <Characteristics
                              label={'Навигационная система (GPS)'}
                              name={product.gps}
                            />
                            <Characteristics
                              label={'Вид транспорта'}
                              name={product.typeAutoD}
                            />
                            <Characteristics
                              label={'Вид транспорта'}
                              name={product.typeAuto}
                            />
                            <Characteristics
                              label={'Артикул запчасти'}
                              name={product.article}
                            />
                            <Characteristics
                              label={'Диаметр, дюймов'}
                              name={product.diameter}
                            />
                            <Characteristics
                              label={'Профессия'}
                              name={product.profession}
                            />
                            <Characteristics
                              label={'Требуемый опыт'}
                              name={product.requiredExperience}
                            />
                            <Characteristics
                              label={'График работы'}
                              name={product.workingHours}
                            />
                            <Characteristics
                              label={'Тип занятости'}
                              name={product.employmentType}
                            />
                            <Characteristics
                              label={'Характер работы'}
                              name={product.natureWork}
                            />
                            <Characteristics
                              label={'Другие условия'}
                              name={product.otherTerms}
                            />
                            <Characteristics
                              label={'Компания'}
                              name={product.company}
                            />
                            <Characteristics
                              label={'зарплата'}
                              name={product.salary}
                            />
                            <Characteristics
                              label={'Категория бизнеса'}
                              name={product.categoryBusiness}
                            />
                            <Characteristics
                              label={'Срок использования'}
                              name={product.periodOfUse}
                            />
                            <Characteristics
                              label={'Кто разместил'}
                              name={product.whoPosted}
                            />
                            <Characteristics
                              label={'Тип объявления'}
                              name={product.type}
                            />
                          </>
                        )}
                        <dt className={styles.desctopInfoCWLable}></dt>
                        <dd className={styles.subsLab}>
                          <div className={styles.openMapDC}>
                            <div className={styles.openMapDCT}></div>
                            <button
                              className={styles.vievMap}
                              onClick={() => {
                                setIsAllCharacteristics(!isAllCharacteristics)
                              }}
                            >
                              <span className={styles.vievMapT}>
                                {isAllCharacteristics
                                  ? 'Скрыть'
                                  : 'все характеристики'}
                              </span>
                            </button>
                          </div>
                        </dd>
                      </li>
                      <li className={styles.desctopInfoCW}>
                        <dl className={styles.descryptContIC}>
                          <dt className={styles.labelInfo}>Просмотры</dt>
                          <dd className={styles.labelInfoI}>
                            {product.viewCount}
                          </dd>
                          <dt className={styles.labelInfo}>Размещено</dt>
                          <dd className={styles.labelInfoI}>
                            {dayjs().to(dayjs(product.createdAt))}
                          </dd>
                        </dl>
                      </li>
                      <li className={styles.desctopInfoCW}>
                        <button className={styles.subsSel}>
                          <div className={styles.subsSelT}>
                            Пожаловаться на объявление
                          </div>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <path
                              fill="#333"
                              d="M10.058 7.765l.684-.73L16.031 12l-5.289 4.965-.684-.73L14.569 12z"
                            ></path>
                          </svg>
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              {!width && (
                <div className={styles.col2}>
                  <ul className={styles.colPriceinfoD}>
                    <div className={styles.colPriceinfoDContainer}>
                      <h3 className={styles.colPriceinfoDTitleI}>
                        <span className={styles.colPriceinfoDTitleIn}>
                          <span className={styles.colPriceinfoDTitleIne}>
                            <span className={styles.priceCount}>
                              {product.price.toString().length === 5
                                ? product.price.toString().slice(0, 2) +
                                  ' ' +
                                  product.price.toString().slice(2)
                                : product.price.toString().length === 6
                                  ? product.price.toString().slice(0, 3) +
                                    ' ' +
                                    product.price.toString().slice(3)
                                  : product.price}{' '}
                              ₽
                            </span>
                          </span>
                        </span>
                      </h3>
                      <div className={styles.butonContainer}>
                        <button
                          className={styles.butonVisibleNumber}
                          onClick={() => {
                            dispatch(actions.isModalMobilePhone(product.user))
                          }}
                        >
                          <span className={styles.visibleNumber}>
                            Показать номер
                          </span>
                        </button>
                        <button className={styles.createMessage}>
                          <span className={styles.visibleNumber}>
                            Написать продавцу
                          </span>
                        </button>
                      </div>
                      <div className={styles.sellInfo}>
                        <div className={styles.sellContainer}>
                          <div className={styles.userContainer}>
                            <figure className={styles.userCell}>
                              <Link to={'/'} className={styles.lintoUser}>
                                <img
                                  src={getAvatarUrl(product.user.avatar)}
                                  width="48"
                                  height="48"
                                  alt="Алена Тиш"
                                  className={styles.userAvatar}
                                />
                              </Link>
                              <div className={styles.userInfoContainer}>
                                <p className={styles.userProfileLinkInner}>
                                  <Link to={'/'} className={styles.profileLink}>
                                    {product.user.firstName}{' '}
                                    {product.user.lastName}
                                  </Link>
                                </p>
                                <p className={styles.userProfileLinkInner}>
                                  на&nbsp;Atuta&nbsp;с&nbsp;
                                  {dayjs(product.user.createdAt).format(
                                    'D MMM YYYY',
                                  )}
                                </p>
                                <div className={styles.ratingContainer}>
                                  <div className={styles.ratingInfoInner}>
                                    <div className={styles.ratingInfoWrap}>
                                      <span className={styles.ratingCount}>
                                        5,0
                                      </span>
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="#FFCD4B"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M8 12.557l3.711 2.366a.5.5 0 0 0 .757-.53l-.991-4.508 3.338-3.058a.5.5 0 0 0-.292-.866l-4.375-.397-1.684-4.202a.5.5 0 0 0-.928 0L5.85 5.564l-4.374.397a.5.5 0 0 0-.292.866l3.338 3.058-.992 4.509a.5.5 0 0 0 .758.529L8 12.557z"
                                        ></path>
                                      </svg>
                                    </div>
                                    <button className={styles.userRate}>
                                      1 отзыв
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </figure>
                          </div>
                          <span className={styles.border}></span>
                          <div className={styles.verryfiInfoContainer}>
                            <div className={styles.verifiProfileContainer}>
                              <div className={styles.verryfiInfoIcconInner}>
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                  <g fillRule="nonzero" fill="none">
                                    <path
                                      fill="#77C226"
                                      d="M12 .6l-9 4v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12v-6l-9-4"
                                    ></path>
                                    <path
                                      fill="#FFF"
                                      d="M12 12.07c3.29 0 6 1.32 6 3-.24.53-.53 1.03-.87 1.53A9.69 9.69 0 0 1 12 20.52a9.69 9.69 0 0 1-5.13-3.92c-.34-.5-.63-1-.87-1.53 0-1.65 2.71-3 6-3zm0-7.47a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                              <div className={styles.vkVerify}>
                                <span className="sc-cOxWqc sc-kfNCuK fhzvlf cqMHBk">
                                  Подтвержденный профиль
                                </span>
                                <div className={styles.verryfiInfoWrapper}>
                                  <div className={styles.verryfiInfoWrap}>
                                    <div className={styles.verryfiInfoIcconI}>
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                      >
                                        <path
                                          fill="#858585"
                                          fillRule="evenodd"
                                          d="M9.804 1.96a7.843 7.843 0 1 1 0 15.687 7.843 7.843 0 0 1 0-15.686zm0 .981a6.863 6.863 0 1 0 0 13.726 6.863 6.863 0 0 0 0-13.726zm.735 5.932v4.902H9.07V8.873h1.47zm-.735-2.99a.98.98 0 1 1 0 1.96.98.98 0 0 1 0-1.96z"
                                          opacity="0.48"
                                        ></path>
                                      </svg>
                                    </div>
                                    <div className={styles.toltipBox}>
                                      <span className={styles.toltipContainer}>
                                        <div className={styles.toltipIn}>
                                          <p className={styles.toltipText}>
                                            Профиль продавца подтвержден через
                                            ВКонтакте и с помощью видео-селфи
                                          </p>
                                          <button
                                            type="button"
                                            color="grey"
                                            className={styles.toltipDetail}
                                          >
                                            Узнать подробнее
                                          </button>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.videoSelf}>
                              <div className={styles.videoSelfIconIn}>
                                <svg width="16" height="16" viewBox="0 0 16 16">
                                  <path
                                    d="M5.6 7.8a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4.8 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 14.4A6.408 6.408 0 0 1 1.6 8c0-.232.016-.464.04-.688a8.05 8.05 0 0 0 4.168-4.296 7.98 7.98 0 0 0 8.328 3.176c.168.568.264 1.176.264 1.808 0 3.528-2.872 6.4-6.4 6.4z"
                                    fill="#9EAFB9"
                                    fillRule="nonzero"
                                  ></path>
                                </svg>
                              </div>
                              <div className={styles.videoSelfWr}>
                                <p className={styles.videoSelfText}>
                                  Видео-селфи проверено
                                </p>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <Link to={'/'} className={styles.linkBuyProduct}>
                        <span className={styles.linkBuyProductText}>
                          Купить
                        </span>
                      </Link>
                      <div className={styles.selS}>
                        <div className={styles.selSEcContainer}>
                          <div className={styles.selSIconI}>
                            <svg width="24" height="24" viewBox="0 0 24 24">
                              <g fill="#77C026" fillRule="evenodd">
                                <path d="M21 4v9.506c0 3.4-2.238 6.406-5.524 7.422L12 22l-3.476-1.072C5.238 19.912 3 16.906 3 13.506V4l9-2 9 2zm-9.477 12.29v-.69h1.619c.23 0 .411-.057.545-.172a.589.589 0 0 0 .202-.472.6.6 0 0 0-.202-.477c-.134-.117-.316-.175-.545-.175H11.56V13.25h1.406c.752 0 1.385-.13 1.9-.388a2.642 2.642 0 0 0 1.161-1.088c.26-.466.389-1.01.389-1.63 0-.64-.135-1.195-.403-1.666a2.701 2.701 0 0 0-1.172-1.091c-.513-.256-1.13-.385-1.853-.385H11.09c-.513 0-.893.124-1.14.37-.246.247-.369.631-.369 1.154v3.179H9.04c-.269.004-.478.076-.63.216a.724.724 0 0 0-.227.56c0 .24.076.426.227.56.152.135.361.204.63.209h.542v1.055H8.93c-.234 0-.417.058-.55.175a.605.605 0 0 0-.197.477c0 .2.066.357.198.472.132.115.315.172.55.172h.688v.689c.048.249.16.441.333.578a.97.97 0 0 0 .619.205.97.97 0 0 0 .619-.205.936.936 0 0 0 .333-.578z"></path>
                                <path d="M12.482 11.705c.664 0 1.15-.12 1.458-.36.308-.239.461-.637.461-1.193 0-.552-.146-.952-.44-1.201-.292-.25-.73-.374-1.31-.374h-1.099v3.128h.93z"></path>
                              </g>
                            </svg>
                          </div>
                          <span className={styles.selSText}>
                            Безопасная сделка
                          </span>
                        </div>
                        <div className={styles.selSEcContainer}>
                          <div className={styles.selSIconI}>
                            <svg width="24" height="24" viewBox="0 0 24 24">
                              <path
                                fill="#6C94C0"
                                fillRule="evenodd"
                                d="M3.393 11.5l.643-3h6.214a.75.75 0 1 0 0-1.5H4.357l.389-1.814A1.5 1.5 0 0 1 6.213 4h8.932a1.5 1.5 0 0 1 1.466 1.814L16.143 8h.15a3 3 0 0 1 2.581 1.472l2.336 3.947a2 2 0 0 1 .258 1.302l-.223 1.562A2 2 0 0 1 19.265 18h-.436a3.001 3.001 0 0 1-5.658 0H13v-.015c-.07.01-.14.015-.213.015H8.83a3.001 3.001 0 0 1-5.72-.2 1.5 1.5 0 0 1-.72-1.614L3.07 13h6.18a.75.75 0 1 0 0-1.5H3.393zM6 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm11.686-7.448a2 2 0 0 0-1.704-.952H15.8l-.9 3.9h4.6l-1.814-2.948zM16 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                              ></path>
                            </svg>
                          </div>
                          <span className={styles.selSText}>
                            Возможна доставка
                          </span>
                        </div>
                      </div>
                      <div>блок для рекламмы</div>
                    </div>
                  </ul>
                </div>
              )}
            </div>
          </div>
          {width && (
            <div className={styles.fixMobileBuy}>
              <div className={styles.fixMobileIn}>
                <div className={styles.fixMobileBuyPr}>
                  {product.price.toString().length === 5
                    ? product.price.toString().slice(0, 2) +
                      ' ' +
                      product.price.toString().slice(2)
                    : product.price.toString().length === 6
                      ? product.price.toString().slice(0, 3) +
                        ' ' +
                        product.price.toString().slice(3)
                      : product.price}{' '}
                  ₽
                </div>
                <Link className={styles.linkBuyProductM} to={'/'}>
                  <span className={styles.linkBuyProductText}>Купить</span>
                </Link>
              </div>
            </div>
          )}
        </main>
      )}
    </>
  )
}

export default IdContainer
