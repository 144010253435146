export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY'
export const TOGGLE_CATEGORY_MOBILE = 'TOGGLE_CATEGORY_MOBILE'
export const TOGGLE_SUB_CATEGORY_MOBILE = 'TOGGLE_SUB_CATEGORY_MOBILE'
export const ONLINE_USER_INFO_REQUEST = 'ONLINE_USER_INFO_REQUEST'
export const ONLINE_USER_INFO_SUCCESS = 'ONLINE_USER_INFO_SUCCESS'
export const TOGGLE_COORDS = 'TOGGLE_COORDS'
export const TOGGLE_IS_BUTTON_USER = 'TOGGLE_IS_BUTTON_USER'
export const ALL_CATEGORY_INFO_REQUEST = 'ALL_CATEGORY_INFO_REQUEST'
export const ALL_CATEGORY_INFO_SUCCESS = 'ALL_CATEGORY_INFO_SUCCESS'
export const ALL_CATEGORY_INFO_ERROR = 'ALL_CATEGORY_INFO_ERROR'
export const ALL_ADVERTISEMENTS_INFO_REQUEST = 'ALL_ADVERTISEMENTS_INFO_REQUEST'
export const ALL_ADVERTISEMENTS_INFO_SUCCESS = 'ALL_ADVERTISEMENTS_INFO_SUCCESS'
export const ALL_ADVERTISEMENTS_INFO_ERROR = 'ALL_ADVERTISEMENTS_INFO_ERROR'
export const ALL_SWIPER_INFO_REQUEST = 'ALL_SWIPER_INFO_REQUEST'
export const ALL_SWIPER_INFO_SUCCESS = 'ALL_SWIPER_INFO_SUCCESS'
export const ALL_SWIPER_INFO_ERROR = 'ALL_SWIPER_INFO_ERROR'

export const PRODUCT_CREATE_STAGE = 'PRODUCT_CREATE_STAGE'
export const CATEGORY_ID = 'CATEGORY_ID'
export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_UPLOAD_IMAGES_PROGRESS = 'PRODUCT_UPLOAD_IMAGES_PROGRESS'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_ERROR = 'PRODUCT_CREATE_ERROR'
export const IS_MODAL_MOBILE_PHONE = 'IS_MODAL_MOBILE_PHONE'
