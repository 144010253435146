import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import logoMobile from '../../assets/logo.png'
import routesLik from '../../constants/routes.constants'
import CategoryList from '../CategoryList'
import ColumnList from '../ColumnList'
import SubCategoryList from '../SubCategoryList'
import { getAvatarUrl, getCategoryUrl } from '../../api'

function Header({
  isPrivate,
  user,
  isCategory,
  isButtonUser,
  onButtonUser,
  onToggleCategoryMobile,
  isCategoryMobile,
  onToggleCategory,
  category,
  onDisableAll,
  subCategory,
}) {
  const [width, setWidth] = useState(window.innerWidth <= 991)
  const [inputSearch, setInputSearch] = useState('')
  const [categoryIndex, setCategoryIndex] = useState(0)
  const [columnIndex, setColumnIndex] = useState(0)
  const [subCategoryIndex, setSubCategoryIndex] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 991)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.headerInner}>
          {width && (
            <>
              <button
                className={styles.categoryOpen}
                onClick={onToggleCategoryMobile}
              >
                {isCategoryMobile ? (
                  <>
                    {subCategory ? (
                      <svg
                        className={styles.categoryButton}
                        viewBox="0 0 24 24"
                      >
                        <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z"></path>
                      </svg>
                    ) : (
                      <svg
                        className={styles.categoryButton}
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                      </svg>
                    )}
                  </>
                ) : (
                  <svg className={styles.categoryButton} viewBox="0 0 24 24">
                    <path d="M15 15H3v2h12zm0-8H3v2h12zM3 13h18v-2H3zm0 8h18v-2H3zM3 3v2h18V3z"></path>
                  </svg>
                )}
              </button>
              <div className={styles.linkHomeM} onClick={onDisableAll}>
                <Link to={'/'} className={styles.linkHomeMH}>
                  <img src={logoMobile} alt={'Юла'} width={35} height={35} />
                </Link>
              </div>
              {isPrivate ? (
                <>
                  <></>
                </>
              ) : (
                <Link to={routesLik.login} className={styles.categoryOpen}>
                  <svg className={styles.categoryButton} viewBox="0 0 24 24">
                    <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4m0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"></path>
                  </svg>
                </Link>
              )}
            </>
          )}
          {!width && (
            <>
              <section className={styles.headerDesktop}>
                <nav className={styles.navContainer}>
                  <div className={styles.navLeftInner}>
                    <div>Местоположение :</div>
                    <div className={styles.geoInfo}>
                      <button className={styles.selectGeo}>
                        <span className={styles.locationIcon}>
                          <svg width="16" height="16" viewBox="0 0 16 16">
                            <path
                              fill="#333"
                              d="M3.812 6.883l2.556.852a3 3 0 0 1 1.897 1.897l.852 2.557 3.979-9.285-9.284 3.98zM15 1L9 15 7.316 9.949a2 2 0 0 0-1.265-1.265L1.001 7 15 1z"
                            ></path>
                          </svg>
                          <span className={styles.country}>Саранск</span>
                        </span>
                      </button>
                    </div>
                  </div>
                  <ul className={styles.briefcase}>
                    <li>
                      <a
                        rel="noopener noreferrer"
                        data-test-action="TariffsPageLink"
                        className={styles.tariffs}
                        href="/promo/tariffs"
                      >
                        <svg width="16" height="16">
                          <path
                            d="M11 4H3.564c-.662 0-.864.039-1.07.149a.818.818 0 0 0-.345.346C2.039 4.7 2 4.902 2 5.564v.076L6.081 7H9.92L14 5.64v-.076c0-.662-.039-.864-.149-1.07a.818.818 0 0 0-.346-.345C13.3 4.039 13.098 4 12.436 4H11zM5.014 3c.031-.458.118-.724.253-.977.174-.326.43-.582.756-.756C6.35 1.093 6.696 1 7.446 1h1.108c.75 0 1.097.093 1.423.267.326.174.582.43.756.756.135.253.222.52.253.977h1.45c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v5.873c0 .892-.093 1.215-.267 1.54a1.81 1.81 0 0 1-.756.757c-.326.174-.65.267-1.54.267H3.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756c-.174-.326-.267-.65-.267-1.54V5.563c0-.892.093-1.215.267-1.54a1.81 1.81 0 0 1 .756-.757c.326-.174.65-.267 1.54-.267h1.45zm1.001 0h3.97a1.209 1.209 0 0 0-.134-.505.818.818 0 0 0-.346-.346C9.306 2.043 9.097 2 8.555 2h-1.11c-.542 0-.751.043-.95.149a.818.818 0 0 0-.346.346c-.07.132-.113.269-.134.505zM2 6.694v4.742c0 .662.039.864.149 1.07a.818.818 0 0 0 .346.345c.205.11.407.149 1.069.149h8.872c.662 0 .864-.039 1.07-.149a.818.818 0 0 0 .345-.346c.11-.205.149-.407.149-1.069V6.694l-3.842 1.28L10 8H6l-.158-.026L2 6.694zM10 8a2 2 0 1 1-4 0h1a1 1 0 1 0 2 0h1z"
                            fill="#333"
                            fillRule="nonzero"
                          ></path>
                        </svg>
                        <span>Для бизнеса</span>
                      </a>
                    </li>
                    <li>
                      <button
                        type="button"
                        data-test-action="MessagesPageLink"
                        className={styles.tariffs}
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16">
                          <g fill="#333">
                            <path d="M3.263 3c-.478 0-.642.125-.81.22a.754.754 0 0 0-.307.327c-.09.18-.122.354-.122.863v7.18c0 .509.032.684.122.863a.76.76 0 0 0 .306.326c.17.096.333.221.811.221h9.474c.478 0 .642-.125.81-.22a.754.754 0 0 0 .307-.327c.09-.18.122-.354.122-.863V4.41c0-.509-.032-.684-.122-.863a.754.754 0 0 0-.306-.326c-.17-.096-.333-.221-.811-.221H3.263zm0-1h9.474c.632 0 .956.067 1.294.259.311.177.56.441.726.773.18.36.243.705.243 1.378v7.18c0 .673-.063 1.018-.243 1.378a1.814 1.814 0 0 1-.726.773c-.338.192-.662.259-1.294.259H3.263c-.632 0-.956-.067-1.294-.259a1.814 1.814 0 0 1-.726-.773c-.18-.36-.243-.705-.243-1.378V4.41c0-.673.063-1.018.243-1.378.167-.332.415-.596.726-.773C2.307 2.067 2.631 2 3.263 2z"></path>
                            <path d="M12.168 4.533l.598.801L8 8.891 3.234 5.334l.598-.801L8 7.643z"></path>
                          </g>
                        </svg>
                        <span>Сообщения</span>
                      </button>
                    </li>
                    <li>
                      <a
                        data-test-action="FavoritesPageLink"
                        className={styles.tariffs}
                        href="/user/favourites"
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16">
                          <path
                            fill="#333"
                            d="M8.333 3.906L8 4.204l-.333-.298c-.758-.679-1.562-1.073-2.334-1.073C3.4 2.833 2.167 4.067 2.167 6c0 2.597 2.127 4.988 5.816 7.175-.007-.004.043-.004.043-.004 3.69-2.21 5.807-4.583 5.807-7.171 0-1.933-1.233-3.167-3.166-3.167-.772 0-1.576.394-2.334 1.073zM8 2.88c.834-.659 1.736-1.047 2.667-1.047 2.485 0 4.166 1.682 4.166 4.167 0 3.044-2.328 5.655-6.286 8.024a1.08 1.08 0 0 1-1.083.006C3.508 11.685 1.167 9.053 1.167 6c0-2.485 1.681-4.167 4.166-4.167.931 0 1.833.388 2.667 1.047z"
                          ></path>
                        </svg>
                        <span>Избранное</span>
                      </a>
                    </li>
                    <li>
                      <a className={styles.tariffs} href="/user/wallet">
                        <svg width="16" height="16" viewBox="0 0 16 16">
                          <path
                            fill="#333"
                            fillRule="evenodd"
                            d="M11.5 1c.828 0 1.5.504 1.5 1.125V3h.5A1.5 1.5 0 0 1 15 4.5v9a1.5 1.5 0 0 1-1.5 1.5H3a2 2 0 0 1-2-2V2.5C1 1.672 1.895 1 3 1h8.5zm2 3H2v9a1 1 0 0 0 1 1h10.5a.5.5 0 0 0 .5-.5V11h-2.857C9.959 11 9 10.105 9 9s.96-2 2.143-2H14V4.5a.5.5 0 0 0-.5-.5zm.5 4h-2.8c-.663 0-1.2.448-1.2 1s.537 1 1.2 1H14V8zm-2.5-6H2.67c-.552 0-.67.224-.67.5s.118.5.67.5H12v-.75c0-.138-.224-.25-.5-.25z"
                          ></path>
                        </svg>
                        <span>Кошелек</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </section>
            </>
          )}
        </div>
        <div className={styles.headerWrapper}>
          {width && !isCategoryMobile && (
            <div className={styles.searchContainer}>
              <svg
                viewBox="0 0 24 24"
                fill="#333"
                className={styles.iconSearchM}
              >
                <path d="M15.29 14.584l5.72 5.72-.707.706-5.72-5.72a7 7 0 1 1 .707-.707zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
              </svg>
              <input
                className={styles.inputSearchMobile}
                placeholder={'Поиск объявлений'}
                value={inputSearch}
                onChange={(e) => {
                  setInputSearch(e.target.value)
                }}
              />
              {inputSearch && (
                <svg
                  className={styles.iconSearchCleanM}
                  viewBox="0 0 24 24"
                  onClick={() => {
                    setInputSearch('')
                  }}
                >
                  <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
              )}
              {inputSearch && (
                <div className={styles.searchResultMobile}>
                  <div>dgsdgs</div>
                </div>
              )}
            </div>
          )}
          {!width && (
            <div className={styles.headerButtonInner}>
              <section className={styles.sectionMain}>
                <div className={styles.flexMaim}>
                  <Link to={routesLik.root} onClick={onDisableAll}>
                    <img src={logoMobile} height={51} alt={'Атата'} />
                  </Link>
                  <button
                    className={styles.openMainDesktop}
                    onClick={onToggleCategory}
                  >
                    {isCategory ? (
                      <svg
                        className={styles.categoryButtonDesktop}
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                      </svg>
                    ) : (
                      <svg
                        className={styles.categoryButtonDesktop}
                        viewBox="0 0 24 24"
                      >
                        <path d="M15 15H3v2h12zm0-8H3v2h12zM3 13h18v-2H3zm0 8h18v-2H3zM3 3v2h18V3z"></path>
                      </svg>
                    )}
                    Категории
                  </button>
                  <div className={styles.searchContainerDesktop}>
                    <div className={styles.autocompleteSearch}>
                      <svg
                        viewBox="0 0 24 24"
                        fill="#333"
                        className={styles.iconSearchM}
                      >
                        <path d="M15.29 14.584l5.72 5.72-.707.706-5.72-5.72a7 7 0 1 1 .707-.707zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                      </svg>
                      <input
                        className={styles.inputSearch}
                        value={inputSearch}
                        onChange={(e) => {
                          setInputSearch(e.target.value)
                        }}
                      />
                      <button
                        type="submit"
                        data-test-action="SearchSubmit"
                        className={styles.butonSearch}
                      >
                        <span className="sc-dvEHMn bdxbDn">Найти</span>
                      </button>
                    </div>
                    <div></div>
                  </div>
                  <a
                    href={isPrivate ? routesLik.productCreate : routesLik.login}
                    data-test-action="ProductCreateLink"
                    className={styles.create}
                    onClick={onDisableAll}
                  >
                    <span>Разместить объявление</span>
                  </a>
                  {isPrivate && user ? (
                    <button
                      className={styles.buttonUser}
                      onClick={onButtonUser}
                    >
                      <img
                        src={getAvatarUrl(user.avatar)}
                        alt={`${user.firstName} ${user.lastName}`}
                      />
                    </button>
                  ) : (
                    <a
                      href="/login"
                      data-test-action="LoginClick"
                      className={styles.login}
                      onClick={onDisableAll}
                    >
                      <span>Войти</span>
                    </a>
                  )}
                </div>
              </section>
              {isCategory && (
                <div className={styles.categoriesContainer}>
                  <div className={styles.categoriesWrapper}>
                    <section className={styles.categoriesInner}>
                      <div
                        className={styles.categoryListContainer}
                        onClick={onToggleCategory}
                      >
                        <div className={styles.categoryListInner}>
                          <ul>
                            {category.map((item, index) => (
                              <CategoryList
                                key={index}
                                index={index}
                                category={item}
                                setCategoryIndex={setCategoryIndex}
                                categoryIndex={categoryIndex}
                              />
                            ))}
                          </ul>
                        </div>
                        <>
                          <div
                            className={
                              category[categoryIndex].columns[columnIndex]
                                ?.subCategory
                                ? styles.categoryListInnerCol
                                : styles.categoryListInnerColN
                            }
                          >
                            <div className={styles.title}>
                              {category[categoryIndex].name}
                            </div>
                            <ul>
                              {category[categoryIndex].columns.map(
                                (item, index) => (
                                  <ColumnList
                                    key={index}
                                    index={index}
                                    column={item}
                                    setColumnIndex={setColumnIndex}
                                    columnIndex={columnIndex}
                                    categoryRoute={
                                      category[categoryIndex].categoryRoute
                                    }
                                  />
                                ),
                              )}
                            </ul>
                          </div>
                          {category[categoryIndex].columns[columnIndex]
                            ?.subCategory && (
                            <div className={styles.subCategoryListInner}>
                              <div className={styles.title}>
                                {
                                  category[categoryIndex].columns[columnIndex]
                                    ?.name
                                }
                              </div>
                              <ul>
                                {category[categoryIndex].columns[
                                  columnIndex
                                ].subCategory.map((item, index) => (
                                  <SubCategoryList
                                    key={index}
                                    index={index}
                                    subCategory={item}
                                    setSubCategoryIndex={setSubCategoryIndex}
                                    subCategoryIndex={subCategoryIndex}
                                    categoryRoute={
                                      category[categoryIndex].categoryRoute
                                    }
                                    columnRoute={
                                      category[categoryIndex].columns[
                                        columnIndex
                                      ].columnRoute
                                    }
                                  />
                                ))}
                              </ul>
                            </div>
                          )}
                        </>
                      </div>
                      {!category[categoryIndex].categoryRoute &&
                        category[categoryIndex].columns[columnIndex].image && (
                          <div className={styles.image}>
                            <img
                              src={getCategoryUrl(
                                category[categoryIndex].columns[columnIndex]
                                  .image,
                              )}
                              alt={''}
                            />
                          </div>
                        )}
                      {category[categoryIndex].categoryRoute &&
                        category[categoryIndex].image && (
                          <div className={styles.categoryImage}>
                            <img
                              src={getCategoryUrl(
                                category[categoryIndex].image,
                              )}
                              alt={''}
                            />
                          </div>
                        )}
                    </section>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
