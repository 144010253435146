// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalViewNumber_ModalViewNumber__ATCpu {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
}

.ModalViewNumber_modalBg__2hv4O {
  position: absolute;
  background: rgba(27, 27, 27, 0.63);
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 4;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalViewNumber/index.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,UAAA;AACF;;AACA;EACE,kBAAA;EACA,kCAAA;EACA,SAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,UAAA;EACA,WAAA;AAEF","sourcesContent":[".ModalViewNumber{\r\n  position: fixed;\r\n  bottom: 0;\r\n  top: 0;\r\n  right: 0;\r\n  left: 0;\r\n  z-index: 3;\r\n}\r\n.modalBg{\r\n  position: absolute;\r\n  background: rgba(27, 27, 27, 0.63);\r\n  bottom: 0;\r\n  top: 0;\r\n  right: 0;\r\n  left: 0;\r\n  z-index: 4;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalViewNumber": `ModalViewNumber_ModalViewNumber__ATCpu`,
	"modalBg": `ModalViewNumber_modalBg__2hv4O`
};
export default ___CSS_LOADER_EXPORT___;
