import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../actions'
import audiobell from '../../assets/audio/got-it-done-613.mp3'
import audiobellM from '../../assets/audio/telegram_soundin.mp3'
import MobileMain from '../MobileMain'
import ModalViewNumber from '../ModalViewNumber'

function SocketClient({ socket, user, isCategoryMobile, category, children }) {
  const dispatch = useDispatch()
  const audioRef = useRef()
  const audioRef2 = useRef()
  const isModalMobilePhone = useSelector(
    (state) => state.app.isModalMobilePhone,
  )

  useEffect(() => {
    if (user) {
      dispatch(actions.joinUserInfo(user))
    }
  }, [dispatch, user])

  useEffect(() => {
    socket.on('checkUserOnlineToMe', (data) => {
      dispatch(actions.joinUserInfoSuccess(data))
    })
    return () => {
      socket.off(`checkUserOnlineToMe`)
    }
  }, [dispatch, socket])

  return (
    <>
      <audio controls ref={audioRef} style={{ display: 'none' }}>
        <source src={audiobell} type="audio/mp3" />
      </audio>
      <audio controls ref={audioRef2} style={{ display: 'none' }}>
        <source src={audiobellM} type="audio/mp3" />
      </audio>
      {children}
      {isCategoryMobile && (
        <MobileMain dispatch={dispatch} category={category} />
      )}
      {isModalMobilePhone && <ModalViewNumber user={isModalMobilePhone} />}
    </>
  )
}
export default SocketClient
