import * as ActionTypes from '../constants/app.constants'
export const toggleLoader = (values) => ({
  type: ActionTypes.TOGGLE_LOADER,
  payload: values,
})

export const toggleCategoryMobile = (values) => ({
  type: ActionTypes.TOGGLE_CATEGORY_MOBILE,
  payload: values,
})

export const toggleCategory = (values) => ({
  type: ActionTypes.TOGGLE_CATEGORY,
  payload: values,
})

export const toggleSubCategory = (values) => ({
  type: ActionTypes.TOGGLE_SUB_CATEGORY_MOBILE,
  payload: values,
})
export const joinUserInfo = (values) => ({
  type: ActionTypes.ONLINE_USER_INFO_REQUEST,
  payload: values,
})
export const joinUserInfoSuccess = (values) => ({
  type: ActionTypes.ONLINE_USER_INFO_SUCCESS,
  payload: values,
})

export const toggleCoords = (values) => ({
  type: ActionTypes.TOGGLE_COORDS,
  payload: values,
})

export const toggleShowUser = (values) => ({
  type: ActionTypes.TOGGLE_IS_BUTTON_USER,
  payload: values,
})

export const allCategoryInfo = (values) => ({
  type: ActionTypes.ALL_CATEGORY_INFO_REQUEST,
  payload: values,
})
export const allCategorySuccess = (values) => ({
  type: ActionTypes.ALL_CATEGORY_INFO_SUCCESS,
  payload: values,
})

export const allCategoryError = (values) => ({
  type: ActionTypes.ALL_CATEGORY_INFO_ERROR,
  payload: values,
})

export const allAdvertisementsInfo = (values) => ({
  type: ActionTypes.ALL_ADVERTISEMENTS_INFO_REQUEST,
  payload: values,
})
export const allAdvertisementsSuccess = (values) => ({
  type: ActionTypes.ALL_ADVERTISEMENTS_INFO_SUCCESS,
  payload: values,
})

export const allAdvertisementsError = (values) => ({
  type: ActionTypes.ALL_ADVERTISEMENTS_INFO_ERROR,
  payload: values,
})

export const allSwiperInfo = (values) => ({
  type: ActionTypes.ALL_SWIPER_INFO_REQUEST,
  payload: values,
})
export const allSwiperSuccess = (values) => ({
  type: ActionTypes.ALL_SWIPER_INFO_SUCCESS,
  payload: values,
})

export const allSwiperError = (values) => ({
  type: ActionTypes.ALL_SWIPER_INFO_ERROR,
  payload: values,
})
export const productCreateStage = (values) => ({
  type: ActionTypes.PRODUCT_CREATE_STAGE,
  payload: values,
})
export const productCreateRequest = (values) => ({
  type: ActionTypes.PRODUCT_CREATE_REQUEST,
  payload: values,
})
export const productUploadImagesProgress = (values) => ({
  type: ActionTypes.PRODUCT_UPLOAD_IMAGES_PROGRESS,
  payload: values,
})
export const productCreateSuccess = (values) => ({
  type: ActionTypes.PRODUCT_CREATE_SUCCESS,
  payload: values,
})
export const productCreateError = (values) => ({
  type: ActionTypes.PRODUCT_CREATE_ERROR,
  payload: values,
})

export const categoryId = (values) => ({
  type: ActionTypes.CATEGORY_ID,
  payload: values,
})

export const isModalMobilePhone = (values) => ({
  type: ActionTypes.IS_MODAL_MOBILE_PHONE,
  payload: values,
})
