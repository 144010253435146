import React from 'react'
import styles from './index.module.scss'

function ModalViewNumber() {
  return (
    <div className={styles.ModalViewNumber}>
      <div className={styles.modalBg} />
    </div>
  )
}
export default ModalViewNumber
